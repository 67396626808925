input {
	.error {
		border-color: #E45E39 !important;
		&-feedback {
			color: #e45e39;
			margin-top: 10px; } } }

.entrance-wrap__form {
	label,.entrance-wrap__form {
		div {
			a {
				top: 26px; } } } }
