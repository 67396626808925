.breadcrumbs {
	padding-top: 16px;
	ul {
		display: flex;
		align-items: center;
		padding: 0;
		margin: 0;
		margin-left: 3px;
		list-style-type: none; }
	li {
		font-size: 13px;
		line-height: 18px;
		position: relative;
		font-family: $OL;
		&::after {
			position: relative;
			right: 0;
			top: 0;
			content: '/';
			font-size: 13px;
			line-height: 18px;
			margin: 0 4px; }
		&:last-child {
			&::after {
				display: none; } } }
	a {
		color: #413744;
		transition: .3s;
		font-family: $default-font;
		&:hover {
			// color: #feda4c
			text-decoration: underline; } } }
