.land {
	color: #121212;
	padding: 40px 30px 50px 30px;
	&-logo {
		font-size: 20px;
		color: $colorLight;
		border-bottom: 2px solid #feda4c;
		transition: all .8s ease;
		b {
			font-family: $OSB; }
		&:hover {
			border: none;
			transform: rotate(120deg); } }

	&-container {
		text-align: center;
		margin-bottom: 128px;
		max-width: 850px;
		margin: auto;
		//border: 1px solid red
		transition: padding .3s; }
	&-h1 {
		//max-width: 750px
		font-size: 48px;
		line-height: 68px;
		font-family: $OL;
		color: $colorLight;
		margin-top: 35px;
		margin-bottom: 20px; }
	&-h2 {
		font-size: 50px;
		line-height: 68px;
		font-family: $OSB;
		text-transform: uppercase; }

	&-text {
		font-size: 18px;
		line-height: 36px;
		margin: 20px 0;
		margin-bottom: 5px;
		font-family: $OL;
		color: #7d93b2;
		width: 100%; }
	&-btn {
		background-color: $yellow;
		font-size: 16px;
		color: $colorLight;
		padding: 15px 30px;
		box-shadow: 0 20px 30px rgba(125, 147, 178, 0.1);
		border-radius: 4px;
		margin-top: 30px;
		transition: all .3s;
		&:hover {
			text-decoration: none;
			color: #121212;
			background-color: darken($yellow, 2%);
			box-shadow: 0 10px 20px rgba(125, 147, 178, 0.2); } }
	&-img {
		max-width: 420px;
		max-height: 320px;
		margin: 20px 0;
		margin-bottom: 35px;
		text-align: center; } }



.background-wave {
    background-position-y: bottom;
    background-repeat-y: no-repeat;
    background-repeat: repeat-x;
    background-size: contain;
    background-color: #EFF6FF;
    background-image: url(/img/img_bg_wave.png); }

.cookies {
	&-popup {
	    background-color: #EFF6FF;
    	min-height: 20px;
    	font-size: 13px;
    	color: #7d93b2;
    	line-height: 26px;
    	padding: 4px 0 4px 30px;
    	position: fixed;

    	left: 0;
    	right: 0;
    	display: none;
    	z-index: 9999;
    	font-family: $OL;
   		.btnAccept {
    		border: 1px solid #d9e8fc;
    		color: #7d93b2;
    		display: inline-block;
    		border-radius: 3px;
    		padding: 0 20px;
    		cursor: pointer;
    		margin: 0 60px 0 10px;
    		&:hover {
    			background-color: #d9e8fc;
    			text-decoration: none; } }

    	.btnClose {
    		color: #7d93b2;
    		float: right;
    		display: inline-block;
    		cursor: pointer;
    		height: 20px;
    		width: 20px;
    		padding: 14px 0;
    		margin: -15px 0 0 0;
    		font-weight: bold; } } }

@keyframes test {
  	0% {
  		opacity: 0; }
  	25% {
  		opacity: 1; }
  	50% {
  		opacity: 1; }
  	100% {
  		opacity: 0; } }
.animator {
	animation: test 5s ease infinite; }

@media screen and (max-width: 768px) {
	.land {
		padding: 25px 10px 50px 10px;
		&-h1 {
			font-size: 28px;
			line-height: 38px;
			margin-top: 20px; }
		&-text {
			font-size: 17px;
			line-height: 30px;
			margin: auto;
			max-width: 450px; } }
	.cookies {
		&-popup {
			background-color: #fff;
			position: fixed;
			top: 0;
			min-height: 20px; } } }
@media screen and(min-width: 768px) {
	.cookies {
		&-popup {
			width: 420px;
			bottom: 0; } }
	.land {
		&-text {
			margin: auto;
			max-width: 650px; } } }
