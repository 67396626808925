.news {
	&-item {
		margin-bottom: 10px;
		padding-bottom: 10px;
		border-bottom: 1px solid #f0f1ff;
		margin: 0 20px 20px;
		&-title {
			font-size: 15px;
			font-family: $default-font;
			a {
				transition: .3s;
				text-decoration: underline;
				&:hover {
					text-decoration: none; } } }
		&-date {
			font-size: 12px;
			line-height: 17px;
			color: $colorTXTsub;
			font-family: $OL; }

		&-text {
			color: $colorTXT;
			font-size: 13px;
			line-height: 24px;
			margin-top: 10px; }
		img {
			max-width: 100%; } } }
