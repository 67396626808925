.header {
	&-top {
		background-color: #273149;
		padding-top: 8px;
		font-family: $OL; }
	.navbar {
		padding-left: 0;
		padding-bottom: 0; }
	&-logo {
		font-size: 20px;
		padding-top: 4px;
		margin-right: 24px;
		align-self: flex-start;
		margin-top: -9px;
		transition: all .8s ease;
		a {
			color: #bcc4e6;
			transition: .3s;
			&:hover {
				text-decoration: none;
				color: #feda4c; }
			&:first-child {
				color: #ffffff;
				display: inline-block;
				padding: 0 2px;
				padding-bottom: 0px;
				border-bottom: 2px solid #feda4c;
				margin-right: 30px;
				&:hover {
					border-bottom: none;
					transform: scale(0.9); } }

			span {
				font-family: $OSB; } } }


	&-nav {
		align-items: flex-start;

		& > {
			ul {
				display: flex;
				margin: 0;
				padding: 0;
				list-style-type: none;
				font-weight: 300;
				text-transform: uppercase;
				& > li {
					font-size: 13px;
					line-height: 19px;
					margin-right: 23px;
					padding-bottom: 13px;

					&.is-active {
						position: relative;
						&::after {
							position: absolute;
							bottom: 0;
							left: 0;
							content: '';
							width: 100%;
							height: 3px;
							background-color: #31a396; } }

					&.show {
						& > a {
							position: relative;
							z-index: 1;
							&::before {
								z-index: -1;
								position: absolute;
								left: -16px;
								top: -22px;
								content: '';
								width: calc(100% + 32px);
								height: 60px;
								background-color: #333e5a; } } }


					.dropdown-menu {
						left: -16px;
						top: 30px;
						font-size: 13px;
						line-height: 18px;
						color: #121212;
						padding: 0;
						border-radius: 3px;
						box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.05);
						border: 0;
						font-family: $default-font;
						text-transform: none;

						& > li > a:hover {

    						background-color: darken(#F6F8F9, 2%);
    						background-image: none; }

						.dropdown-item {
							border-radius: 3px;
							line-height: 40px;
							display: inline-block;
							padding-left: 19px;
							padding-right: 35px;
							transition: .3s; } }


					&:last-child {
						margin-right: 0; }
					& > a {
						color: #bcc4e6;
						transition: .3s;
						//text-transform: none
						span {
							display: inline-flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							color: #ffffff;
							background-color: #0ec379;
							border-radius: 3px;
							width: 23px;
							height: 20px;
							margin-right: -3px;
							margin-top: -3px; }
						&.dropdown-toggle::after {
							display: none; }
						&:hover {
							text-decoration: none;
							color: #BDD4FF; }

						b {
							font-family: $OSB;
							display: block;
							font-weight: normal; } } } } } }
	&-rocket {
		margin-left: 71px;
		font-size: 20px;
		font-family: $OSSB;
		font-size: 14px;
		line-height: 19px;
		font-weight: normal;
		text-transform: uppercase;

		.fa {
			transition: all .8s ease;
			margin-right: 7px;
			&:hover {
				transform: rotate(-45deg); } }
		a {
			color: #bcc4e6;
			transition: .3s;

			&:hover {
				text-decoration: none;
				color: #feda4c; } } }

	&-user {
		margin-left: 108px;
		display: flex;
		align-items: flex-start;
		p {
			margin: 0;
			margin-bottom: 2px;
			&:last-child {
				margin-bottom: 0; } }
		&-photo {
			margin-top: -10px;
			margin-right: 11px;
			box-shadow: 0px 10px 30px rgba(255, 255, 255, 0.18);
			border-radius: 50%;
			overflow: hidden;
			transition: all .8s ease;
			&:hover {
				box-shadow: none;
				//transform: translateY(-5px)
				transform: rotate(360deg); } }
		&-info {
			margin-top: -8px;
			color: #bcc4e6;
			font-size: 13px;
			text-align: left; }
		&-logout {
			margin-left: 32px;
			font-size: 18px;
			margin-top: -3px;
			transition: all .8s ease;
			a {
				color: #bcc4e6;
				// transition: .3s
				&:hover {
					text-decoration: none;
					color: #feda4c; } }
			&:hover {
				transform: rotate(-45deg);
				transform: scale(1.2); } } }
	&-notification {
		margin-right: 26px;
		a {
			display: block;
			font-size: 23px;
			margin-top: -8px;
			color: #fff;
			position: relative;
			color: #bcc4e6;
			transition: .3s;
			&:hover {
				text-decoration: none;
				color: #feda4c;
				span {
					color: #fff; } }
			span {
				position: absolute;
				left: -6px;
				top: 4px;
				font-size: 11px;
				color: #fff;
				font-family: $default-font;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				background-color: #0ec379;
				transition: .3s; } } }
	&-playground {
		background-color: #18223a;
		//margin-top: -7px
		font-family: $OL;
		min-height: 45px;
		.is-active {
			background-color: #263047; }
		a {
			display: flex;
			align-items: center;
			justify-content: center;
			color: #bcc4e6;
			font-size: 14px;
			padding-right: 16px;
			padding-left: 7px;
			padding-top: 12px;
			padding-bottom: 13px;
			transition: .3s;
			white-space: nowrap;
			&:hover {
				text-decoration: none;
				background-color: #263047; }
			.fa {
				margin-right: 10px;
				font-size: 20px; } } }
	&-my-playground {}
	&-add-playground {} }


.header-nav {
	li:hover {
		.dropdown-menu {}
 } }		//	display: block

.notify {
	&-item {
		padding: 10px 5px;
		border-bottom: 0.5px dashed $lineColor;
		&-title {
			color: $colorTXTdark;
			font-size: 13px; }
		&-date {
			font-size: 10px;
			color: $colorTXTsub;
			text-align: right; }
		&-text {
			font-size: 12px;
			color: $colorTXTsub; }
		&:last-child {
			border: none; }
		a {
			color: $colorLink;
			font-size: 13px;
			margin: 0;
			display: inline;
			text-decoration: underline;
			&:hover {
				text-decoration: none;
				color: darken($colorLink, 5%); } } }
	&-status {
		&-new {
			color: $colorActive; }
		&-old {
			color: $colorModerate; } }
	&-new {
		color: $colorActive; }
	&-old {
		color: $colorModerate; } }
