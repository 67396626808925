.offers {
	padding-top: 13px;

	&-table {
		flex: 1 100%; }
	&-shadow {
		background: #ffffff;
		padding: 17px 15px;
		box-shadow:  0px 0px 50px rgba(4, 36, 59, 0.08);
		//box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.0514606)
		border-radius: 3px; }
	&-block {
		margin-bottom: 41px;
		padding: 0;
		&:last-child {
			margin-bottom: 0; }
		.col-btns {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 24px;
			padding-right: 19px;
			background-color: #f6fafc;
			margin-left: -15px;
			margin-right: -15px;
			margin-bottom: -18px;
			padding: 15px 15px;
			.btn {
				width: 160px;
				height: 40px; }
			a.btn-reset {
				flex: 1;
				text-align: center; } }
		.col-checkbox {
			padding-left: 4px;
			margin-bottom: 0;
			label {
				font-size: 13px;
				line-height: 18px; }
			& + .col-input {
				margin-top: 17px; } }
		.col-input {
			margin-bottom: 13px;
			label {
				//padding-left: 5px
				font-size: 12px;
				line-height: 17px;
				font-family: $OSB;
				color: #5e574c;
				margin-bottom: 10px;
				text-transform: uppercase; } }
		&-title {
			margin: 0 0 8px 2px;
			display: inline-block;
			text-transform: uppercase;
			font-size: 12px;
			line-height: 17px;
			font-family: $OSSB;
			color: #5e574c;
			border-bottom: 2px solid #ffdb4d;
			padding-bottom: 5px; } }
	&-user {
		&-icon {
			text-align: center;
			font-size: 50px;
			line-height: 50px;
			color: #0088cc;
			margin-top: 1px;
			margin-bottom: 17px;
			transition: all .8s ease;
			&:hover {
				box-shadow: none;
				//transform: translateY(-5px)
				transform: rotate(360deg); } }
		&-name {
			font-size: 15px;
			line-height: 20px;
			color: #333333;
			text-align: center;
			margin-bottom: 5px; }
		&-text {
			font-size: 13px;
			line-height: 18px;
			color: #000000;
			text-align: center;
			a {
				color: #1e96d6;
				&:hover {
					text-decoration: underline; } } } }
	&-title {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 19px;
		color: #b5bbc2;
		text-transform: uppercase;
		font-family: $OL;
		justify-content: center;
		margin-top: 4px;
		&::after,&::before {
			position: static;
			display: block;
			content: '';
			flex: 1;
			height: 1px;
			background-color: #f0f1ff; }
		&::after {
			margin-left: 26px; }
		&::before {
			margin-right: 26px; } }
	&-new {
		margin-bottom: 16px;
		padding-left: 10px;
		a {
			font-size: 12px;
			border: none;
			border-bottom: 1px dashed $colorLink;
			&:hover {
				text-decoration: none;
				border: none; } }
		&:nth-child(2) {
			margin-top: 21px; }
		&:last-child {
			margin-bottom: 0; }
		&-top {
			font-size: 14px;
			line-height: 17px;
			color: $colorTXT;
			margin-bottom: 3px;
			font-family: $default-font;
			a {
				font-size: 14px;
				color: #337ab7;
				border: none;
				text-decoration: underline;
				&:hover {
					text-decoration: none; } } }
		&-text {
			color: $colorTXT;
			font-size: 13px;
			line-height: 21px;
			overflow: hidden;
			transition: height 0.3s linear; } }



	.right-title {
		align-self: flex-start;
		color: $colorTXT;
		span {
			font-size: 16px; } }

	&-table {
		margin-top: -22px;
		.table {
			min-width: 970px;
			//border-collapse: separate
			//border-spacing: 0 10px
			&-center {
				text-align: center; } }
		thead {
			tr {
				td {
					font-size: 12px;
					line-height: 17px;
					color: #413744;
					font-family: $OSSB;
					text-transform: uppercase;
					border: 0;
					border-bottom: 1px solid #f0f1ff; } } }

		tbody {
			tr {

				// box-shadow: 0 0 20px rgba(4,36,59,.03)
				td {
					border: 0;
					border-bottom: 1px dashed #f0f1ff;
					font-size: 13px;
					line-height: 18px;
					color: #413744; } } } }

	&-offer {
		width: 200px;
		padding-top: 15px;
		b {
			display: block;
			font-weight: normal;
			font-family: $OSSB;
			font-size: 13px;
			line-height: 17px;
			margin-bottom: 13px;
			a {
				color: #413744; } }
		& > img {
			margin-bottom: 9px; }
		span {
			display: block;
			font-size: 12px;
			line-height: 17px;
			color: #413744;
			margin-bottom: 9px;
			img {
				margin-right: 9px; }
			&:last-child {
				margin-bottom: 0; } } }




	&-desc {
		padding-top: 16px !important;
		width: 350px;
		&-links {
			margin-bottom: 10px;
			font-size: 13px;
			line-height: 18px;
			color: #5e574c;
			font-family: $OL;
			a {
				color: #5e574c;
				font-family: $default-font; } }
		p {
			margin: 0;
			font-size: 14px;
			line-height: 19px;
			color: #413744;
			margin-bottom: 10px; }
		span {
			font-family: $OL;
			font-size: 13px;
			line-height: 18px;
			color: #5e574c;
			b {
				font-weight: normal;
				font-family: $default-font; } } }
	&-indicators, &-rates {

		padding: 0 !important;
		p {
			margin: 0;
			font-size: 13px;
			line-height: 41px;
			padding: 0 11px;
			color: #413744;
			&:nth-child(even) {
				background-color: #f6fafc; }
			a {
				text-transform: uppercase;
				border-bottom: 1px dashed #413744;
				color: #413744;
				transition: .3s;
				&:hover {
					text-decoration: none;
					color: #337ab7;
					border-color: #337AB7; } } } }
	&-indicators {
		width: 140px; }
	&-rates {
		width: 80px;
		font-family: $OSSB;
		p {
			padding-right: 0;
			white-space: nowrap; } }
	&-price {
		min-width: 220px;
		text-align: center;
		a {
			display: inline-block;
			font-size: 20px;
			line-height: 27px;
			color: #337ab7;
			border-bottom: 1px dashed #337ab7;
			transition: .3s;
			margin-bottom: 20px;
			text-align: center;
			&:hover {
				color: #0ec379;
				border-color: #0EC379;
				text-decoration: none; }
			&.btn {
				display: flex;
				align-items: center;
				justify-content: center;
				outline: none;
				margin: 0 auto;
				margin-top: 21px;
				width: 123px;
				height: 40px;
				border-radius: 30px;
				border: 0;
				color: #ffffff;
				font-size: 12px;
				line-height: 17px;
				border: solid 2px transparent;
				background-color: #37cd8f;
				box-shadow: 0 10px 25px rgba(55,205,143,.3);
				transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
				// font-family: $OSSB
				&:hover, &:active, &:focus {
					color: #ffffff;
					background-color: darken(#37cd8f, 5%);
					border: solid 2px transparent;
					box-shadow: 0 5px 15px hsla(155, 58%, 45%, 0.5); } } } } }
.result {
	margin-left: auto;
	flex: 0 237px;
	margin-top: 15px;
	.col-input {
		display: flex;
		align-items: center;
		label {
			font-size: 13px;
			line-height: 18px;
			margin-bottom: 0; }
		.select-wrap {
			flex: 0 67px;
			margin-left: 12px;

			select {
				height: 35px; } } } }
.sorting {
	margin-left: auto;
	flex: 0 295px;
	position: relative;
	top: -8px;
	.col-input {
		display: flex;
		align-items: center;
		label {}
		.select-wrap {
			flex: 0 180px;
			select {
				height: 36px;
				font-size: 13px;
				line-height: 18px; } }
		label {
			font-size: 13px;
			line-height: 18px;
			color: #433a46;
			margin-right: 13px;
			margin-bottom: 0; } } }

.pagination {
	ul {
		display: flex;
		align-items: center;
		margin: 0;
		padding: 0;
		list-style-type: none; }
	li {
		margin-right: 8px;
		&:last-child {
			margin-right: 0; }
		&.active {
			a {
				background-color: #ffd800; } } }
	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 39px;
		height: 39px;
		background-color: #f6f8f9;
		border-radius: 5px;
		color: #413744;
		font-size: 13px;
		line-height: 18px;
		border-bottom: 3px solid transparent;
		transition: .3s;
		&:hover {
			border-color: #ffd800;
			text-decoration: none; } } }


.offer {
	&-img {
    	width: 160px;
    	height: 55px;
    	object-fit: contain;
    	border: 1px solid #f0f1ff; } }
