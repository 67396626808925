.faq {
	// margin: 0 10px
	ul {
		list-style: none {
    	margin: 0;
    	padding: 0; }
		li {
    		cursor: pointer;
    		padding: 20px;
    		margin-bottom: 10px;
    		&:hover {
    			background-color: #f9f9f9;
    			box-shadow: 0 4px 20px rgba(80,102,123,.16), 0 2px 4px rgba(80,102,123,.08); }
    		&.active {
    			box-shadow: 0 4px 20px rgba(80,102,123,.16), 0 2px 4px rgba(80,102,123,.08); } } }
	p {
		font-size: 14px {
    	margin-top: 5px; }
		display: none; }
	&-item {
		margin: 10px 0;
		&-title {
			color: $colorTXTblack;
			font-size: 16px;
			a {
				color: $colorTXTblack;
				text-decoration: none;
				border: none;
				transition: 0.3s;
				&:hover {
					border-bottom: 1px dashed $colorTXTblack; } } }
		&-text {
			font-size: 14px;
			line-height: 24px; } } }
