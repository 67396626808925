.cabinet {
	padding-top: 23px; }

.cab {
	&-item {
		margin-bottom: 36px;
		&:last-child {
			margin-bottom: 0; }
		.right-title {
			margin-bottom: 10px;
			margin-left: 9px;
			& + .cab-table {
				margin-top: -10px; } } }
	&-table {
		margin-bottom: 7px;
		&:last-child {
			margin-bottom: 0; }
		table {
			width: 100%;
			border-collapse: collapse; }
		tr {
			&:nth-child(even) {
				background-color: #fafcfd; }
			td {
				//padding: 0 9px
				line-height: 40px;
				color: #413744;
				font-size: 13px;
				a {
					color: #3498db; }
				&:last-child {
					font-family: $OSSB;
					text-align: right; } } } }
	&-text {
		font-size: 13px;
		line-height: 18px;
		color: #73748e;
		padding-left: 7px;
		padding-top: 5px;
		padding-right: 25px; }
	&-ref {
		background-color: #f6fafc;
		margin-left: -10px;
		margin-right: -10px;
		margin-bottom: -20px;
		margin-top: 30px;
		padding-top: 11px;
		padding-bottom: 19px;
		padding-left: 22px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-right: 54px;
		.fa {
			color: #337ab7; }
		button {
			background-color: transparent;
			border: 0;
			padding: 0;
			cursor: pointer;
			&:hover {
				text-decoration: underline; } }
		button:first-child {
			font-size: 15px;
			line-height: 20px;
			color: #5e574c; } }
	&-graph {
		background: #FFFFFF;
		border: 1px solid rgba(39, 122, 195, 0.15);
		box-sizing: border-box;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 17px;
		line-height: 23px;
		text-align: center;
		color: #73748e;
		height: 295px;
		font-family: $OL;
		margin-top: 10px;
		&-wrapper {
			padding-bottom: 23px; } }
	&-offers {
		margin-bottom: 20px;
		text-align: center;
		padding-top: 26px;
		img {
			margin-bottom: 9px; }

		.offers-desc-links {
			margin-bottom: 21px; }
		.price {
			display: inline-block;
			font-size: 20px;
			line-height: 27px;
			color: #337ab7;
			border-bottom: 1px dashed #337ab7;
			transition: .3s;
			margin-bottom: 28.5px;
			text-align: center;
			&:hover {
				color: #0ec379;
				border-color: #0EC379;
				text-decoration: none; } }
		& > p {
			font-size: 13px;
			line-height: 18px;
			color: #b5bbc2;
			text-transform: uppercase;
			font-family: $OL;
			margin: 0;
			margin-bottom: 28px; } }
	&-btn {
		margin-left: -15px;
		margin-right: -15px;
		margin-bottom: -20px;
		background-color: #f6fafc;
		display: flex;
		justify-content: center;
		padding-top: 16px;
		padding-bottom: 16px; } }
