.ticket {
	&-title {
		font-size: 15px;
		font-family: $default-font;
		margin: 5px 0 0; }
	&-block {
		border: 1px solid rgba(39,122,195,.15); }
	&-status {
		border-radius: 3px;
		font-size: 12px;
		&.open {
			color: $colorBadge;
 }			// font-family: $OSSB
		&.closed {
			color: $colorGray; } }
	&-badge {
		font-size: 11px;
		//font-family: $OSSB
		background-color: $colorBadge;

		padding: 2px 6px;
		border-radius: 3px;
		color: #fff;
		margin-left: 5px;
		white-space: nowrap; }
	&-notify {
		font-size: 12px;
		background-color: $colorBadge;
		padding: 2px 6px;
		border-radius: 3px;
		color: #fff; }
	&-subject {
		font-size: 14px;
		&.new {
			font-family: $OSSB; }
		a {
			text-decoration: underline;
			&:hover {
				text-decoration: none; } } }

	&-category {
		font-size: 12px;
		color: $colorTXTsub; }

	&-date {
		font-size: 12px;
		line-height: 17px; }
	&-priority {
		text-decoration: none;
		&-default {
			font-size: 12px;
			color: $colorGray; }
		&.fire {
			color: $colorDanger;
			font-family: $OSSB; } }
	&-id {
		font-size: 12px; }
	&-avatar {
		width: 50px;
		height: 50px;
		object-fit: cover;
		border-radius: 50%;
		background-color: $colorBGLight;
		//border: 1px solid black
		box-shadow: 0 0 50px rgba(4,36,59,.08);
		transition: all .8s ease;
		&:hover {
			transform: translateY(-5px);
			transform: rotate(360deg); } }

	&-name {
		font-family: $default-font;
		font-size: 15px;
		line-height: 23px;
		color: $colorTXTdark; }
	&-time {
		font-family: $OL;
		color: $colorTXTsub; }
	&-message {
		font-size: 14px;
		line-height: 26px;
		margin: 10px 0;
		//color: #121212
		&.new {
			background-color: #fbfdf3;
			border-left: 2px solid #9ccc4d;
			padding-left: 10px; } }

	&-table {
		margin: 5px 0 10px;
		width: 100%;
		border-radius: 3px;
		tr {
			td {
				padding: 5px; } }
		&.support {
			background-color: $bgLight; }

		&-info {
			width: 100%;
			tr {
				height: 35px;
				td {
					//word-wrap: none
					&:first-child {
						width: 50%;
						font-family: $default-font; }
					&:last-child {
						width: 50%;
						font-family: $OL; } } } } }

	&-id {
		font-family: $OSSB; }

	&-attach {
		margin: 5px 0 10px;
		.col-md-2, .col-6 {
			padding-left: 0;
			padding-right: 10px;
			padding-bottom: 10px; }
		img {
			width: 150px;
			height: 150px;
			object-fit: cover;
			border-radius: 3px;
			background-color: $colorBGLight;
			background-color: #fff;
			box-shadow: 0 0 50px rgba(4,36,59,.08);
			cursor: pointer;
			transition: all .3s ease;
			margin-right: 10px;
			&:hover {
				transform: translateY(-5px); } } }

	&-textarea {
		height: 200px; } }


