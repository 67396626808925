* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	&:before, &:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box; } }

*::-webkit-input-placeholder {
	color: #5e574c;
	opacity: 1;
	transition: opacity .25s; }

*:focus::-webkit-input-placeholder {
	opacity: 0; }

*:-moz-placeholder {
	color: #5e574c;
	opacity: 1;
	transition: opacity .25s; }

*:focus::-moz-placeholder {
	opacity: 0; }

*::-moz-placeholder {
	color: #5e574c;
	opacity: 1;
	transition: opacity .25s; }

*:focus:-moz-placeholder {
	opacity: 0; }

*:-ms-input-placeholder {
	color: #5e574c;
	opacity: 1;
	transition: opacity .25s; }

*:focus:-ms-input-placeholder {
	opacity: 0; }

body input:focus:required:invalid,
body textarea:focus:required:invalid {
	color: #5e574c; }
body input:required:valid,
body textarea:required:valid {
	color: #5e574c; }

*, body {
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	-webkit-focus-ring-color: rgba(0,0,0,0);
	outline: none; }

html, body {
	height: 100%; }


body {
	font-size: 13px;
	min-width: 320px;
	position: relative;
	line-height: 1.25;
	font-family: $default-font;
	overflow-x: hidden;
	opacity: 1; }

h1,h2,h3,h4,h5,h6 {
	font-size: 1em;
	font-weight: normal;
	margin: 0; }
a {
	color: $colorLink;
	&:hover {
		color: darken($colorLink, 3%); } }
*:focus {
    outline: none; }
.preloader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 100;
	background-color: #FBF8F8;
	img {
		position: relative;
		left: 50%;
		top: 50vh;
		transform: translate(-50%, -50%); } }

.center-content {
	text-align: center; }

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto; }

.container {
	max-width: 1470px;
	padding: 0 15px;
	margin: 0 auto; }


