.footer {
	padding: 0 25px;
	padding-top: 66px;
	padding-bottom: 30px;
	&-main-title {
		display: flex;
		align-items: center;
		margin-bottom: 2px;
		font-family: $OL;
		font-size: 13px;
		line-height: 18px;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.6825px;
		color: #b5bbc2;
		&::after, &::before {
			position: relative;
			display: block;
			flex: 1;
			height: 1px;
			background-color: #f0f1ff;
			content: ''; }
		&::before {
			margin-right: 42px; }
		&::after {
			margin-left: 42px; } }
	&-logo {
		margin-bottom: 5px;
		a {
			padding-bottom: 2px;
			color: #121212;
			font-size: 20px;
			line-height: 27px;
			position: relative;
			display: inline-block;
			transition: .3s;
			border-bottom: 2px solid #feda4c;
			&:hover {
				// color: #121212
				text-decoration: none;
				border-bottom: 2px solid #121212; }
			span {
				font-family: $OSB; } } }
	&-text {
		font-size: 12px;
		line-height: 19px;
		margin: 0;
		color: #5a5a5a;
		margin-bottom: 18px; }
	&-title {
		font-size: 14px;
		line-height: 19px;
		color: #121212;
		letter-spacing: 0.9375px;
		text-transform: uppercase;
		margin-top: 14px; }
	&-ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
		margin-top: 9px;
		li {
			font-size: 13px;
			line-height: 18px;
			margin-bottom: 8px;
			font-family: $OL;
			&:last-child {
				margin-bottom: 0; } }
		a {
			color: #5a5a5a;
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: .3s;
			&:hover {
				text-decoration-color: inherit; } } } }

.payment {
	display: flex;
	flex-wrap: wrap;
	&-link {
		flex: 0 50%;
		filter: grayscale(100%);
		transition: .3s;
		display: flex;
		align-items: center;
		margin-bottom: 2px;
		&:nth-child(even) {
			padding-left: 4px; }
		&:hover {
			filter: grayscale(0); } } }

.social {
	margin-top: 10px;
	p {
		font-size: 14px;
		line-height: 19px;
		margin: 0;
		color: #5a5a5a;
		margin-bottom: 8px;
		font-family: $OL;
		&:last-child {
			margin-bottom: 0; }
		a {
			color: #5a5a5a;
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: .3s;
			&:hover {
				text-decoration-color: inherit; } } }
	& > a {
		display: inline-flex;
		vertical-align: top;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		color: #fff;
		background-color: #676767;
		transition: .3s;
		margin-bottom: 23px;
		&:hover {
			background-color: #5081b8;
			text-decoration: none; }
		&.social-telegram {
			background-color: transparent;
			color: #676767;
			margin-left: 2px;
			font-size: 30px;
			margin-right: 7px;
			&:hover {
				color: #0088cc;
				background-color: transparent; } } } }
