.payment {
	&-purse {
		font-family: $OSSB;
		font-size: 12px;
		line-height: 23px;
		color: $colorTXTdark; }

	&-system {
		font-size: 12px;
		color: $colorTXTsub; }
	&-converted {
		font-size: 12px;
		color: $colorTXTsub;
		padding: 0; }
	&-name {
		font-size: 13px;
		font-family: $OSSB; }
	&-currency {
		color: $colorTXTsub; }


	&-summ {
		font-family: $OSSB;
		&-plus {
			color: $colorYes; }
		&-minus {
			color: $colorNo; } }

	&-id {
		font-size: 12px; }
	&-date {
		font-size: 12px;
		line-height: 17px;
		&-update {
			font-size: 11px; }
		&-done {
			font-size: 11px;
			color: $colorTXTsub; } }

	&-tip {
		color: $colorTXT;
		&:hover {
			color: $colorTXT; } }
	&-info {
		font-size: 12px;
		line-height: 14px;
		color: $colorTXTsub; }

	&-balance {
		&-old {
			color: $colorTXTsub; }
		&-new {
			font-family: $OSSB;
 } }			//color: $colorTXTdark
	&-money {
		&-main {
			font-size: 12px;
			font-family: $OSSB;
			color: $colorTXT; }
		&-wait {
			font-size: 12px;
			color: $colorTXTsub; } }
	&-history {
		&-title {
			font-family: $OSSB;
			font-size: 13px;
			line-height: 17px;
			color: $colorTXTdark; }
		&-subtitle {
			font-size: 12px;
			color: $colorTXTsub; } } }
