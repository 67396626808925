@import "vars";

.col-img {
	img {
		max-width: 100%;
		width: 100%; } }
/*==========  Desktop First  ==========*/
@media only screen and (max-width : 1470px) {
	.header-user {
		margin-left: auto; }
	.header-rocket {
		margin-left: auto;
		margin-right: auto; }
	.col-img {
		text-align: center;
		img {
			max-width: 100%;
			width: 100%; } }
	.header-nav {
		flex: 1; }
	.navbar {
		flex: 1; }
	.header-nav>ul>li.show>a::before {
		left: -10px;
		width: calc(100% + 20px); }
	.header-nav>ul>li.show .dropdown-menu {
		left: -10px; }
	.right-title {
 }		// margin-top: 20px
	.col-form .col-lg-6 {
		flex: 0 calc(50% - 21px); }
	.navbar-toggler {
		position: relative;
		top: -8px;
		&:focus {
			outline: none; } }
	.navbar-toggler span {
		display: block;
		background-color: #bcc4e6;
		height: 3px;
		width: 25px;
		margin-top: 4px;
		margin-bottom: 4px;
		-webkit-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-o-transform: rotate(0deg);
		transform: rotate(0deg);
		position: relative;
		left: 0;
		opacity: 1; }
	.navbar-toggler span:nth-child(1),
	.navbar-toggler span:nth-child(3) {
		-webkit-transition: transform .35s ease-in-out;
		-moz-transition: transform .35s ease-in-out;
		-o-transition: transform .35s ease-in-out;
		transition: transform .35s ease-in-out; }
	.navbar-toggler:not(.collapsed) span:nth-child(1) {
		position: absolute;
		left: 12px;
		top: 10px;
		-webkit-transform: rotate(135deg);
		-moz-transform: rotate(135deg);
		-o-transform: rotate(135deg);
		transform: rotate(135deg);
		opacity: 0.9; }
	.navbar-toggler:not(.collapsed) span:nth-child(2) {
		height: 12px;
		visibility: hidden;
		background-color: transparent; }
	.navbar-toggler:not(.collapsed) span:nth-child(3) {
		position: absolute;
		left: 12px;
		top: 10px;
		-webkit-transform: rotate(-135deg);
		-moz-transform: rotate(-135deg);
		-o-transform: rotate(-135deg);
		transform: rotate(-135deg);
		opacity: 0.9; } }
@media only screen and (max-width : 1300px) {
	.header-nav>ul>li {
		margin-right: 11px; } }
@media only screen and (max-width : 1250px) {
	.header-logo a:first-child {
		margin-right: 10px; } }
/* Large Devices, Wide Screens */
@media only screen and (max-width : 1200px) {
	.offers-block:not(:first-child) {
		margin-top: 41px; }
	.offers-shadow {
		margin-right: 0; }
	.header-user {
		margin-left: 19px; }
	.header-rocket {
		margin-left: 18px; }
	.header-nav {
		flex-basis: 100%;
		flex-grow: 1; }
	.header .navbar {
		flex: 1 100%;
		padding: .5rem 1rem; }
	.header-rocket, .header-user {
		display: inline-flex;
		vertical-align: top; }
	.header-nav {
		padding-top: 10px;
		padding-bottom: 20px; }
	.header-rocket {
		margin-left: 0; }
	.header-nav>ul {
		flex: 1 100%;
		display: flex;
		margin-bottom: 20px; }
	.header-nav>ul>li {
		margin-right: 10px; }
	.header-nav>ul>li.show>a::before {
		display: none; }
	.header-nav>ul>li.show .dropdown-menu {
		left: 50%;
		transform: translate(-50%, 0);
		top: 23px; } }
/* Medium Devices, Desktops */
@media only screen and (max-width : 992px) {
	.offers-block:last-child {
		margin-top: 0; }
	.col-form .col-lg-6 {
		flex: 1 100%;
		max-width: 100%; }
	.col-form .col-lg-6:first-child {
		margin-right: 0;
		padding-right: 0;
		border: 0;
		margin-bottom: 21px;
		padding-bottom: 6px;
		border-bottom: 1px solid #f0f1ff; }
	.add-playground .btn {
		margin: 0 auto;
		margin-top: 20px; }
	.offers-block:last-child {
		margin-bottom: 35px; } }
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
	.offers-block:not(:first-child) {
		margin-top: 0; }
	.footer .col-md-3 {
		text-align: center;
		margin-bottom: 20px; }
	.header-nav>ul {
		flex-direction: column;
		align-items: center;
		text-align: left;
		&>li {
			margin-bottom: 5px;
			padding-bottom: 5px;
			margin-right: 0;
			width: 270px;
			&:last-child {
				margin-bottom: 0; } } }
	.header-nav {
		text-align: center; }
	.header-nav>ul>li.show .dropdown-menu {
		left: 33%;
		transform: translate(-50%, 0);
		top: 23px; } }
@media only screen and (max-width : 575px) {
	.sorting {
		margin-top: 0;
		flex: 1 100%;
		justify-content: flex-end;
		margin-top: 20px; }
	.sorting .col-input .select-wrap {
		flex: 0 160px; } }
/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
	.footer {
		.col-6 {
			margin-bottom: 10px;
			// flex: 1 100%
			// max-width: 100%
			text-align: center; } }
	.payment {
		justify-content: center; }
	.payment-link {
		flex: 0 120px;
		text-align: center;
		justify-content: center; }
	.col-table .btn-hollow {
		margin-top: 10px; }
	.header-playground {
		.container .row .d-flex {
			& > div {
				flex: 0 50%; } } }
	.header-playground a {
		font-size: 12px; }
	.header-playground a .fa {
		font-size: 18px; }
	.header-playground a {
		padding-right: 10px; }
	.footer {
		padding-top: 40px; }
	.header-user {
		margin-left: 12px; }
	.header-rocket .fa {
		margin-right: 2px; }
	.header-notification {
		margin-right: 15px; }
	.offers-block .col-btns {
		flex-wrap: wrap;
		flex-direction: column;
		align-items: center;
		padding-right: 0;
		.btn {
			margin-bottom: 10px; } } }


/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
	/**/ }


/*==========  Mobile First  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
	/**/ }


/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
	/**/ }


/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
	/**/ }


/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
	/**/ }


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
	/**/ }

