.offer {
	font-size: 13px;
	line-height: 24px;
	color: $colorTXT;
	padding-top: 13px;
	p {
		margin-bottom: 10px; }
	&-tabs {
		margin: 15px 0;
		.row {
			padding: 0;
			margin-left: 0;
			margin-right: 0; }
		.col-md-6, {
			padding: 0;
			padding-right: 10px; } }
	&-description, &-promo {
		font-size: 13px;
		line-height: 24px;
		color: $colorTXT;
		overflow: hidden; }
	&-promo {
		font-size: 14px; }
	&-rules {
		font-size: 13px;
		line-height: 24px;
		color: $colorTXT; }
	&-category, &-target {
		font-size: 13px;
		color: $colorTXT;
		span {
			font-family: $OL; }
		a {
			text-decoration: none;
			color: $colorTXT;
			&:hover {
				text-decoration: underline; } } }
	&-target {
		font-family: $OSSB;
		span {
			font-family: $default-font;
			color: #121212; } }
	&-geo {
		margin: 5px 0 0 10px;
		max-height: 80px;
		overflow: hidden;
		&:hover {
			overflow-y: scroll; }
		span {
			display: block;
			font-size: 12px;
			line-height: 17px;
			color: $colorTXT;
			margin-bottom: 9px;
			img {
				margin-right: 9px; }
			&:last-child {
				margin-bottom: 0; } } }
	&-logo {
    	width: 260px;
    	height: 65px;
    	object-fit: contain;
    	border: 1px solid #f0f1ff;
    	margin: 3px 0;
    	&.small {
    		width: 160px;
    		height: 55px; } }



	&-site {
		font-family: $OSSB;
		color: $colorTXT;
		width: 185px; }
	&-moderator {

		font-style: italic;
		color: $colorTXT;
		width: 320px;
		span {
			font-size: 12px; } }
	&-links {
		.col-md-3, .col-md-4 {
			padding: 0 8px 0 0;
			&:last-child {
				padding: 0; } } } }

.tab-content {
	font-size: 13px;
	margin: 15px 0; }






.stat {
	&-date {
		font-size: 12px;
		&-update {
			font-size: 11px;
			font-color: $colorGray; } }
	&-link {
		color: $colorTXT;
		text-decoration: none;
		&:hover {
			color: darken($colorLink, 5%);
			text-decoration: underline; } }
	&-site {
 }		//font-family: $OSSB

	&-price {
		font-family: $OSSB; }
	&-geo {
		font-size: 12px;
		img {
			width: 20px;
			height: 15px;
			object-fit: contain;
			border: 1px solid #f0f1ff; } }

	&-table {
		&-conversion {
			tbody {
				tr {
					td:first-child {
						font-family: $OSSB; } } } } } }




