$default-font: "OpenSans-Regular", sans-serif;
$OSB: "OpenSans-Bold", sans-serif;
$OSSB: "OpenSans-SemiBold", sans-serif;
$OL: "OpenSans-Light", sans-serif;

$colorTXT: #566376;
$colorTXTblack: #413744;
$colorTXTdark: #333333;
$colorTXTsub: #868e96;
$colorYes: #0ec379;
$colorNo: #e74c3c;
$colorWait: #868e96;
$colorCancel: #f39c12;
$colorModerate: #ccc;
$colorLight: #4c6280;
$bgLight: #F6FAFC;

$colorBorder: #f0f1ff;


$colorLink: #337ab7;
$colorInput: #d0d6e6;
$colorSuccess: #28a745;
$colorGray: #cccccc;

$colorDanger: #c91915;
$colorBadge: #0ec379;
$colorError: #e45e39;

$yellow: #ffd800;
$colorActive: #ffd800;
$colorNoActive: #f6f8f9;
$colorBGLight: #ffd800;
$tableRow: #f9f9fa;
$lineColor: #edeff0;
