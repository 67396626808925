.add-playground {
	padding-top: 12px;
	margin-bottom: 40px;
	.col-lg-9 {
		padding-left: 0; } }
.my-playground {
	.col-lg-9 {
		padding-left: 0; } }
.no-playground {
	text-align: center;
	font-size: 14px;
	line-height: 19px;
	color: #413744;
	padding-top: 33px;
	padding-bottom: 33px;
	border-bottom: 1px solid #f0f1ff;
	a {
		color: #337ab7;
		transition: .3s;
		text-decoration: underline;
		&:hover {
			text-decoration: none; } } }
.right-title, .left-title {
	font-size: 14px;
	line-height: 19px;
	position: relative;
	color: $colorTXT;
	padding-bottom: 8px;
	a {
		color: $colorTXT;
		text-decoration: none;
		&:hover {
			text-decoration: underline; } }
	&::after {
		position: absolute;
		left: 0;
		bottom: 0;
		content: '';
		width: 52px;
		height: 2px;
		background-color: #ffdb4d; }
	span {
		text-transform: uppercase;
		font-family: $OSB; } }
.left-title {
	font-size: 12px;
	padding-bottom: 3px; }

.shadow-wrapper {
	box-shadow: 0 0 50px rgba(#04243B, .08);
	padding: 15px 15px 15px;
	margin-bottom: 10px; }

.right-text {
	font-size: 14px;
	line-height: 19px;
	margin: 0;
	color: #413744;
	margin-bottom: 11px; }

.btn {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	width: 180px;
	background-color: #0ec379;
	border-radius: 3px;
	font-size: 15px;
	line-height: 20px;
	color: #ffffff;
	padding: 0 45px;
	transition: .3s;
	border: solid 2px transparent;
	// background-color: #37cd8f
	box-shadow: 0 10px 25px rgba(55,205,143,.3);
	transition: background-color 0.4s, color 0.4s, box-shadow 0.4s;
	&:hover {
		background-color: darken(#0ec379, 5%);
		color: #ffffff;
		text-decoration: none; }
	&-hollow {
		color: #413744;
		font-size: 12px;
		line-height: 17px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 3px;
		border: 1px solid #0EC379;
		height: 32px;
		padding: 0 25px;
		width: 130px;
		text-transform: uppercase;
		transition: .3s;
		&:hover {
			color: #ffffff;
			background-color: #0ec379;
			text-decoration: none;
			.fa {
				color: #ffffff; } }
		.fa {
			font-size: 13px;
			line-height: 13px;
			color: #413744;
			transition: .3s;
			margin-right: 5px; } }
	&.sub {
		width: auto;
		padding: 0 15px;
		font-size: 13px;
		background-color: #e6e6e6;
		color: $colorTXT;
		box-shadow: 0 2px 0 rgba(0 , 0, 0, 0.16);
		&:hover {
			background-color: darken(#e6e6e6, 2%); }
		&:active {
			background-color: lighten(#e6e6e6, 3%); } } }

.btn-reset {
	border: none;
	background: none;
	color: #6f6c68;
	font-size: 13px;
	height: 30px;
	width: 80px;
	cursor: pointer;
	text-decoration: none;
	&:focus {
		outline: none; }
	&:hover {
		text-decoration: underline; } }
.col-form {
	padding-top: 17px;
	.col-lg-6 {
		padding: 0;
		flex: 0 501px;
		max-width: calc(50% - 21px);
		&:first-child {
			border-right: 1px solid #f0f1ff;
			padding-right: 24px;
			margin-right: 21px; } } }


.col-input {
	margin-bottom: 18px;
	label {
		font-size: 14px;
		line-height: 19px;
		color: #413744;
		margin-bottom: 10px; }
	input {
		width: 100%;
		display: block;
		border: 1px solid #E2E6ED;
		border-radius: 3px;
		height: 46px;
		font-size: 13px;
		font-family: $OL;
		padding-left: 8px;
		padding-right: 8px;
		color: #6f6c68;
		box-shadow: unset;
		outline: none;
		-webkit-appearance: none;
		&:not(:placeholder-shown) {
			font-family: $OSSB; } }
	textarea {
		width: 100%;
		display: block;
		border: 1px solid #E2E6ED;
		border-radius: 3px;
		min-height: 79px;
		resize: none;
		color: #413744;
		font-family: inherit;
		font-size: 13px;
		line-height: 18px;
		padding: 11px 10px;
		box-shadow: unset;
		outline: none;
		-webkit-appearance: none; } }


.select-wrap, .select-wrap-multiple {
	position: relative;
	width: 100%;
	&::after {
		position: absolute;
		right: 21px;
		top: 50%;
		transform: translate(0, -50%);
		content: '';
		width: 12px;
		height: 12px;
		background-image: url(../img/triangle.png);
		background-size: cover;
		cursor: pointer; }
	select {
		border: 1px solid #e2e6ed;
		border-radius: 3px;
		width: 100%;
		height: 46px;
		font-size: 13px;
		color: #6f6c68;
		font-family: $OSSB;
		padding-left: 8px;
		padding-right: 29px;
		-webkit-appearance: none;
		appearance: none;
		background-color: transparent;
		position: relative;
		z-index: 1;
		box-shadow: unset;
		outline: none; } }
.select-wrap-multiple {
	select {
		height: 300px; } }

.col-checkbox {
	margin-bottom: 5px;
	&:last-child {
		margin-bottom: 0; }
	input {
		display: none;
		&:checked + label {
			&::before {
				background-color: #0ec379; }
			&::after {
				opacity: 1; } } }
	label {
		padding-left: 29px;
		position: relative;
		cursor: pointer;
		&::before {
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			width: 18px;
			height: 18px;
			background: #F0F0F0;
			border-radius: 3px;
			transition: .3s; }
		&::after {
			position: absolute;
			left: 0;
			top: 0;
			content: '';
			width: 18px;
			height: 18px;
			background-image: url(../img/check.png);
			background-position: center;
			background-repeat: no-repeat;
			transition: .3s;
			opacity: 0; }
		b {
			display: block;
			color: #5e574c;
			font-size: 13px;
			line-height: 18px;
			font-family: $OSSB;
			font-weight: normal;
			margin-bottom: 4px; }
		span {
			display: block;
			font-size: 13px;
			line-height: 18px;
			color: #5e574c;
			font-family: $OL; } } }


.col-title {
	font-size: 15px;
	line-height: 20px;
	color: #333333;
	text-align: center;
	margin-bottom: 14px; }
.col-text {
	margin: 0;
	font-size: 13px;
	line-height: 18px;
	color: #121212;
	text-align: center;
	margin-top: 6px;
	a {
		color: #337ab7;
		transition: .3s;
		text-decoration: underline;
		&:hover {
			// color: #337ab7
			text-decoration: none; } }

	& + .btn {
		margin: 0 auto;
		margin-top: 15px; } }

.v-center {
   display: flex;
   align-items: center; }

.bgblue {
	background-color: $tableRow;
	height: 70px; }




.col-table {
	border-bottom: 1px solid #f0f1ff;
	.btn-hollow {
		margin-top: -25px;
		margin-left: auto;
		position: relative;
		z-index: 1; }
	.table {
		margin: 0;
		thead {
			tr {
				td {
					color: #413744;
					font-family: $OSSB;
					font-size: 12px;
					line-height: 17px;
					text-transform: uppercase;
					border: 0;
					border-bottom: 2px solid #f0f1ff;
					&:nth-child(2) {
						padding-left: 4px; }
					&:nth-child(4) {
						text-align: center; }
					&:nth-child(6) {
						text-align: center; } } } }
		tbody {
			tr {
				&:nth-child(even) {
					background-color: #F6F8F9; }
				td {
					border: 0;
					font-size: 13px;
					line-height: 18px;
					color: #413744;
					&:first-child {
						padding-left: 10px; }
					&:nth-child(3) {
						//padding-top: 30px
						max-width: 140px; }
					&:nth-child(5) {
						color: #5e574c;
						padding-top: 14px;
						max-width: 170px; }
					&:nth-child(6) {
						max-width: 150px;
						font-size: 20px;
						height: 100%;
						vertical-align: inherit;
						text-align: center;
						.complite {
							color: #0ec379; }
						.not-complite {
							color: #c91915; } }
					&:nth-child(4) {
						height: 100%;
						vertical-align: inherit;
						text-align: center;
						a {
							font-size: 20px;
							color: #5e574c;
							transition: .3s;
							&:hover {
								color: #0ec379;
								text-decoration: none; } } }
					&:nth-child(2) {
						padding-left: 4px;
						font-size: 14px;
						line-height: 19px;
						max-width: 250px;
						a {
							display: block;
							font-family: $OL;
							font-size: 13px;
							line-height: 18px;
							color: #413744;
							transition: .3s;
							&:hover {
								// color: #ffdb4d
								text-decoration: underline; } }
						span {
							display: block;
							font-size: 11px;
							line-height: 15px;
							color: #6f6f6f; } } } } } }

	.table-offer {
		margin: 0;
		width: 100%;
		thead {
			tr {
				height: 42px;
				td {
					color: #413744;
					font-family: $OSSB;
					font-size: 12px;
					line-height: 17px;
					padding: 10px;
					text-transform: uppercase;
					border: 0;
					border-bottom: 2px solid #f0f1ff; } } }

		tbody {
			tr {

				&:nth-child(even) {
					background-color: #F6F8F9; }
				td {
					vertical-align: top;
					padding: 10px;
					border: 0;
					font-size: 13px;
					line-height: 18px;
					color: #413744;
					order-bottom: 2px dashed #f0f1ff; } } } } }

.offer {
	&-logo {
    	width: 160px;
    	height: 55px;
    	object-fit: contain;
    	border: 1px solid #f0f1ff;
    	margin: 10px 0; }
	&-category {
		font-size: 13px; }
	&-name {
		font-size: 15px;
		font-weight: 400;
		color: #413744;
		font-family: $OSSB; }
	&-ajax {
		text-decoration: none;
		border-bottom: 1px dashed #337ab7;
		font-size: 20px;
		color: #337ab7;
		&:hover {
			color: #337ab7;
			border: none;
			text-decoration: none; } }


	&-like {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		font-size: 13px;
		line-height: 20px;
		color: #b5bbc2;
		margin-bottom: 6px;
		text-transform: uppercase;
		font-family: $OL;
		margin: 20px 0;
		span {
			padding: 0 24px; }
		&:after,&:before {
			position: relative;
			display: block;
			content: '';
			width: 50px;
			height: 1px;
			background-color: #f0f1ff; } }
	&-btn {
    	border-radius: 25px;
    	background-color: #fff;
    	padding: 9px 17px;
    	border: 1.5px solid #ffd800;
    	cursor: pointer;
    	font-size: 12px;
    	font-weight: 400;
    	text-transform: uppercase;
    	transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    	//margin-top: -5px
    	&:hover {
    		background-color: #ffd800;
    		color: #000; } }
	&-category {
		font-size: 13px;
		color: #5e574c;
		span {
			font-family: $OL; } } }
