.btn-reset {
	font-family: $default-font;
	font-size: 13px;
	line-height: 18px;
	color: #6f6c68;
	text-decoration: none;
	transition: .3s;
	&:hover {
		color: #0088cc;
		text-decoration: {} } }



.tip {
	border-bottom: 1px dashed #413744;
	color: #413744;
	transition: .3s;
	cursor: pointer;
	&:hover {
		text-decoration: none;
		color: #337ab7;
		border-color: #337AB7; } }

.gbg {
    padding: 3px 8px;
    border-radius: 3px;
    background: -webkit-gradient(linear,left top,right top,from(#fce4ed),to(#ffe8cc));
    background: linear-gradient(to right,#fce4ed,#ffe8cc); }


.separator {
		display: flex;
		align-items: center;
		margin-bottom: 2px;
		font-family: $OL;
		font-size: 13px;
		line-height: 18px;
		text-align: center;
		text-transform: uppercase;
		letter-spacing: 0.6825px;
		color: #b5bbc2;
		&::after, &::before {
			position: relative;
			display: block;
			flex: 1;
			height: 1px;
			background-color: #f0f1ff;
			content: ''; }
		&::before {
			margin-right: 42px; }
		&::after {
			margin-left: 42px; } }

.yes {
    color: $colorYes; }
.no {
    color: $colorNo; }

.left {
   // float: left
 }   //ext-align: left
.right {
   // float: right
 }   //ext-align: right
.status {
	font-size: 10px;
	text-transform: uppercase;
	//padding: 5px 10px
	font-family: $OSSB;
	border-radius: 3px;
	color: #fff;
	&-active {
		color: $colorYes; }
	//	background-color: $colorYes
	&-noactive {
		color: $colorWait; }
	//	background-color: $colorModerate
	&-declined {
		color: $colorNo; }
	//	background-color: $colorNo
	&-canceled {
		color: $colorNo;
 }		//color: $colorCancel
 }	//	background-color: $colorNo

.ajax, .ajax-small {
	text-decoration: none;
	border-bottom: 1px dashed $colorLink;
	color: $colorLink;
	transition: 0.3s;
	&:hover {
		color: lighten($colorLink, 10%);
		border-bottom: 1px dashed lighten($colorLink, 10%);
		text-decoration: none; }
	&-no, {
		color: $colorNo;
		border-bottom: 1px dashed $colorNo;
		text-decoration: none;
		&:hover {
			color: lighten($colorNo, 20%);
			border-bottom: 1px dashed lighten($colorNo, 5%);
			text-decoration: none; } } }
.ajax-small {
	font-size: 12px; }

.size {
	&-default {
		font-size: 13px; }
	&-medium {
		font-size: 15px; }
	&-big {
		font-size: 18px; } }

.btnYellow {
    border-radius: 25px;
    background-color: #fff;
    padding: 5px 15px;
    border: 1.5px solid #ffd800;
    cursor: pointer;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;
    //margin-top: -5px
    &:hover {
    	background-color: #ffd800;
    	color: #000; }
    &:focus {
    	outline: none; } }

.modal {
	&-title {
		font-size: 15px;
		color: $colorTXTdark; }
	&-header {
		border-bottom: 1px dashed #f0f1ff;
		margin-bottom: 10px; }
	&-footer {
		border: none;
		display: block;
		padding-top: 0; }
	&-content {
		border: none;
		box-shadow:  0px 0px 50px rgba(0, 0, 0, 0.3); }
	&-body {
		padding-bottom: 0; }
	.row {
		margin: 0; } }


.float-label {
	&-input {
		position: relative;
		margin-bottom: 10px;
		width: 100%;
		input {
			width: 100%;
			height: 46px;
			padding-left: 13px;
			padding-right: 13px;
			font-size: 16px;
			line-height: 22px;
			color: #566376;
			border-radius: 3px;
			border: 1px solid $colorInput;
			font-family: inherit;
			transition: .3s;

			&.error {
				border-color: #e45e39; }
			&:not(:placeholder-shown) + {
				label {
					top: -11px;
					left: 3px;
					font-size: 12px;
					color: $colorTXT;
					font-family: $OSSB; } }
			&:focus {
				border-color: darken($colorInput, 3%);
				& + label {
					top: -11px;
					left: 3px;
					font-size: 12px;
					color: $colorTXT;
					font-family: $OSSB; } } }
		label {
			position: absolute;
			left: 4px;
			top: 12px;
			font-size: 15px;
			line-height: 22px;
			color: #d3dae4;
			padding: 0 10px;
			background-color: #ffffff;
			transition: .3s;
			font-family: $OL;
			border-radius: 3px;
			&.error {
				display: none !important; } }
		.copy {
			position: absolute;
			right: 13px;
			top: 13px;
			font-size: 17px;
			line-height: 17px;
			text-decoration: none;
			transition: .3s;
			color: $colorInput;
			&:hover {
				color: lighten($colorLink, 10%); } } } }

.bgbottom {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 5px;
	padding-right: 19px;
	background-color: darken(#f6fafc, 1%);
	padding: 15px 15px;
	margin-left: -16px;
	margin-right: -16px;
	margin-bottom: -16px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px; }

.text {
	font-size: 13px;
	line-height: 19px;
	color: $colorTXT;
	padding-top: 5px;

	&-success {
		color: $colorSuccess; }
	&-long {
		height: 150px;
		overflow-y: scroll;
		margin: 5px 0; }
	&-countries {
		padding: 10px;
		height: 300px;
		overflow-y: scroll;
		margin: 5px 0;
		border: 1px dashed #f0f1ff; }
	&-key {
		p {
			font-family: $OL; } }
	&-small {
		font-size: 12px; }
	&-referal {
		font-family: $OSSB;
		color: $colorTXTdark; }
	&-sub {
		font-size: 12px;
		line-height: 17px; }

	&-title {
		font-family: $OSSB;
		font-size: 12px;
		line-height: 23px;
		color: $colorTXTdark; }
	&-id {
		font-size: 12px; }

	&-note {
		font-size: 12px;
		color: $colorTXTsub; }

	&-date {
		font-size: 12px;
		line-height: 17px;
		&-update {
			font-size: 11px; }
		&-done {
			font-size: 11px;
			color: $colorTXTsub; } }
	&-wrap {
		word-break: break-all; } }

.nav-pills {
	margin-top: 5px;
	.nav-link {
		padding: 5px 10px;
		&.active {
			color: $colorTXT;
			background-color: #f9f7f7; } } }

.table {


	&-full {
		min-width: 970px; }
	&-bordered {
		tbody {
			tr, td {
				border: 0.5px solid #f1f3f4; } } }
	&-sort {
		thead {
			tr {
				td {
					cursor: pointer;
					color: #413744;
					transition: .3s;
					&:hover {
						text-decoration: underline; }
					&.text-center {
						cursor: default;
						text-decoration: none; } } } }
		tbody {
			tr {
				height: 50px; } } }

	&-traffic {
		p {
			margin: 5px 0;
			font-size: 12px; }
		a {
			color: $colorTXT;
			text-decoration: none;
			border-bottom: 1px dashed $colorTXT;
			&:hover {
				border-bottom: 1px dashed $colorTXT; } } } }


.table-zebra {
	margin: 0;
	width: 100%;
	border-bottom: 0.5px solid #f0f1ff;

	td {
    	padding: 10px; }
	b {
		font-family: $OSSB; }

	thead {
		tr {
			td {
				color: #413744;
				font-family: $OSSB;
				font-size: 12px;
				line-height: 17px;
				//padding: 10px
				text-transform: uppercase;
				border: 0;
				border-bottom: 1px solid #f0f1ff; } }
		.sub td {
      		font-family: $default-font;
      		text-transform: none;
      		line-height: 24px;
 } }      		//border-bottom: 1px solid #edeff0

	tbody {
		tr {

			&:nth-child(even) {
				//background-color: #F6F8F9
				// background-color: #f6fafc
				background-color: $tableRow; }
			td {
				//vertical-align: top
				//padding: 10px
				// border: 0
				font-size: 13px;
				line-height: 19px;
				color: $colorTXT {


 } } } }					//border-bottom: 1px dashed #f0f1ff
	tfoot td {
    	border-bottom: 0;
    	border-top: 1px solid #edeff0;
    	background-color: #f6f8f9;
    	font-family: $OSSB;
    	&:first-child {
    		-moz-border-radius: 0 0 0 6px {
      		-webkit-border-radius: 0 0 0 6px;
      		border-radius: 0 0px 0 6px; } }
    	&:last-child {
      		-moz-border-radius: 0 0 6px 0;
      		-webkit-border-radius: 0 0 6px 0;
      		border-radius: 0px 0px 6px 6px; } } }

/* zebra */

.zebra {

  	td {
  		padding: 10px; }
  	//b
  	//	font-family: $OSSB
   ///   border-bottom: 1px solid #f9f7f7;
  	tbody tr:nth-child(even) {
    	background: #f6f8f9;
    	-webkit-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
    	-moz-box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset;
    	box-shadow: 0 1px 0 rgba(255, 255, 255, 0.8) inset; }
  	td {
    	text-align: left;
    	text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
    	border-bottom: 1px solid #edeff0;
    	&:first-child {
    		-moz-border-radius: 6px 0 0 0;
    		-webkit-border-radius: 6px 0 0 0;
    		border-radius: 6px 0 0 0; } }
  	thead {
    	tr {
    		td {}
    		//font-family: $OSSB
      //	font-size: 12px
      //	text-transform: uppercase
    		.sub td {
      			text-transform: none; } } }
  	tfoot td {
    	border-bottom: 0;
    	border-top: 1px solid #edeff0;
    	background-color: #f6f8f9;
    	font-family: $OSSB;
    	&:first-child {
    		-moz-border-radius: 0 0 0 6px {
      		-webkit-border-radius: 0 0 0 6px;
      		border-radius: 0 0px 0 6px; } }
    	&:last-child {
      		-moz-border-radius: 0 0 6px 0;
      		-webkit-border-radius: 0 0 6px 0;
      		border-radius: 0px 0px 6px 6px; } } }

.bordered {
	tbody, tfoot {
		td {
			border: 0.5px solid #f1f3f4; } } }



.daterangepicker {
	.applyBtn {
		border: none; }
	.btn {
		margin: 0; } }

.radiocustom {
	margin: 8px 3px;
	color: $colorTXT;
	.btn {
		width: 50px;
		font-size: 13px;
		background-color: $colorNoActive;
		border: none;
		color: $colorTXT;
		&:hover, &.active, &:focus {
			color: $colorTXT;
			font-family: $OSSB;
			background-color: lighten($colorActive, 5%); } } }

.tabs {
	margin: 10px 0; }


.dropzone {
	border: 2px dotted #d0d6e6;
	width: 100%;
	min-height: 80px;
	margin: 10px 0;
	&:hover {
		background-color: #f7f7f9; }

	.dz-message {
		margin-top: 25px;
		text-align: center; }

	.dz-preview {
		margin-top: 0; } }


.table-settings, .table-balance, .table-referal, .table-dashboard {
	width: 100%;
	thead {
		tr {
			td {
				// color: #413744
				font-family: $OSSB;
				font-size: 12px;
				line-height: 17px;
				//padding: 10px
				text-transform: uppercase;
				border: 0;
				height: 35px;
				border-bottom: 1px solid #f0f1ff; } } }
	&.solid {
    	tr {
    		border-bottom: 0.5px solid #edeff0; } }
	tbody {
			tr {
				height: 45px;
				border-bottom: 0.7px dashed #edeff0;
				&:last-child {
    				border-bottom: none; }
				td {
    				padding-top: 10px;
    				vertical-align: top; } } } }



.table-balance {
	thead {
		tr {
 } }			// box-shadow:  0px 10px 30px rgba(0, 0, 0, 0.05)
	td:first-child {
		font-family: $OSSB; }

	td:nth-child(2), td:nth-child(3) {
		text-align: right; } }


.table-dashboard {
	thead {
		tr {
 } }			// box-shadow:  0px 10px 30px rgba(0, 0, 0, 0.05)
	td:first-child {}

	td:nth-child(2), td:nth-child(3) {
		text-align: right;
		font-family: $OSSB;
		font-size: 12px; }
	tbody {
		tr {
			border: none; } } }


.settings {
 	&-item {
 } } 		//font-family: $OSSB
.app {
	&-icon {
		img {
			margin: 5px 0;
			background-color: #fff;
			border-color: 3px;
			width: 70px;
			height: 70px; } } }

.hint {
    background-color: #FEF4D8;
    border: 0;
    padding: 16px 20px;
    border-radius: 3px;
    margin-bottom: 5px;
    color: $colorTXTdark; }
.comment {
    font-size: 12px;
    line-height: 14px;
    background-color: #FEF4D8;
    padding: 5px 10px;
    border-radius: 3px;
    margin: 5px 0; }

.shadow {
		background: #ffffff;
		padding: 17px 15px;
		box-shadow:  0px 0px 50px rgba(4, 36, 59, 0.08);
		//box-shadow: 0px 10px 45px rgba(0, 0, 0, 0.0514606)
		border-radius: 3px;
		margin-left: 0;
		margin-right: 0; }


.nav-tabs {
    border-bottom: 1px solid #f0f1ff;

    .nav-item {
    	font-size: 13px;
    	margin-right: 2px;
    	box-shadow: 0px 15px 10px -15px rgba(4, 36, 59, 0.08);
    	.active {
    		// font-family: $OSSB
    		//border: none
 }    		//background-color: $tableRow
    	&:hover {
    		//border: none
 }    		//background-color: $tableRow
    	a {
    		color: $colorTXT;
    		text-decoration: none;
    		&-hover {
    			text-decoration: none; } } } }
.fa-star {
	color: $yellow; }

.rotate {
	// animation: rotate 6.5s linear infinite
	animation: rotate 6.5s linear infinite;
 }	// animation: example 6.5s linear
@keyframes rotate {
  to {
    transform: rotate(360deg); } }
@keyframes example {
  0%, 100% {
    margin-left: 200px; }
  10%, 90% {
    margin-left: 0px; } }


.notify-popup {
	.dropdown-menu {
		left: -150px;
		top: 30px;
		width: 300px;
		border: none;
		padding: 0;
		box-shadow:  0px 0px 50px rgba(4, 36, 59, 0.08);
		.content {
			font-size: 13px;
			color: $colorTXT;
			font-family: $default-font;
			padding: 10px; } } }

.graph {
		background: #FFFFFF;
		border: 1px solid rgba(39, 122, 195, 0.15);
		box-sizing: border-box;
		border-radius: 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 17px;
		line-height: 23px;
		text-align: center;
		color: #73748e;
		height: 310px;
		font-family: $OL;
		margin: 10px 0;
		padding: 10px 5px 0; }

table {
	thead {
		td {
			white-space: nowrap; } } }

.icons {
	&-block {
		font-size: 13px;
		color: #f2f2f2; } }

.dropdown-item {
	font-size: 13px; }
